<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="960" persistent>
    <v-card>
      <v-card-title class="px-4 pt-4 gap-2"> Player </v-card-title>
      <v-data-table
        class="roster-table"
        disable-sort
        disable-filtering
        disable-pagination
        hide-default-footer
        :headers="headers"
        :items="items"
        item-key="clientRosterPlayerId"
      >
        <template #item.image="props">
          <v-edit-dialog :return-value.sync="props.item.image" large persistent @save="save" @cancel="cancel">
            <v-img
              height="48"
              width="48"
              :alt="props.item?.imageAltText || 'Player Image'"
              :src="props.item?.image || '/default-img.png'"
              class="rounded-sm"
            />
            <template v-slot:input>
              <div class="py-4">
                <!-- <label class="grey--text text--darken-1">Page Sponsor Image</label> -->
                <upload-image
                  :value="props.item?.image"
                  height="200"
                  width="200"
                  :fileUrl="props.item.image"
                  @input="items[props.index].image = $event"
                  key="2"
                  class="mb-5 mt-2"
                />
                <!-- :client-id="program.clientId" -->
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.jerseyNumber="props">
          <v-edit-dialog persistent :return-value.sync="props.item.jerseyNumber">
            <span v-if="props.item.jerseyNumber" :id="`${props.item.clientRosterPlayerId}-0`">{{
              props.item.jerseyNumber
            }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-0`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.jerseyNumber"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 0)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 0)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.firstName="props">
          <v-edit-dialog persistent :return-value.sync="props.item.firstName">
            <span v-if="props.item.firstName" :id="`${props.item.clientRosterPlayerId}-1`">{{
              props.item.firstName
            }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-1`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.firstName"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 1)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 1)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.lastName="props">
          <v-edit-dialog persistent :return-value.sync="props.item.lastName">
            <span v-if="props.item.lastName" :id="`${props.item.clientRosterPlayerId}-2`">{{
              props.item.lastName
            }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-2`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.lastName"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 2)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 2)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.position="props">
          <v-edit-dialog persistent :return-value.sync="props.item.position">
            <span v-if="props.item.position" :id="`${props.item.clientRosterPlayerId}-3`">{{
              props.item.position
            }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-3`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.position"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 3)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 3)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.class="props">
          <v-edit-dialog persistent :return-value.sync="props.item.class">
            <span v-if="props.item.class" :id="`${props.item.clientRosterPlayerId}-4`">{{ props.item.class }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-4`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.class"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 4)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 4)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.height="props">
          <v-edit-dialog persistent :return-value.sync="props.item.height">
            <span v-if="props.item.height" :id="`${props.item.clientRosterPlayerId}-5`">{{ props.item.height }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-5`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.height"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 5)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 5)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.weight="props">
          <v-edit-dialog persistent :return-value.sync="props.item.weight">
            <span v-if="props.item.weight" :id="`${props.item.clientRosterPlayerId}-6`">{{ props.item.weight }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-6`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.weight"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 6)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 6)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.hometown="props">
          <v-edit-dialog persistent :return-value.sync="props.item.hometown">
            <span v-if="props.item.hometown" :id="`${props.item.clientRosterPlayerId}-7`">{{
              props.item.hometown
            }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-7`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.hometown"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 7)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 7)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.highSchool="props">
          <v-edit-dialog persistent :return-value.sync="props.item.highSchool">
            <span v-if="props.item.highSchool" :id="`${props.item.clientRosterPlayerId}-8`">{{
              props.item.highSchool
            }}</span>
            <span v-else class="text--disabled" :id="`${props.item.clientRosterPlayerId}-8`">&mdash;</span>
            <template v-slot:input>
              <div class="py-4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="props.item.highSchool"
                  single-line
                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, props.item.clientRosterPlayerId, 8)"
                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, props.item.clientRosterPlayerId, 8)"
                ></v-text-field>
              </div>
            </template>
          </v-edit-dialog>
        </template>
        <template #item.bio="props">
          <v-edit-dialog large :return-value.sync="props.item.bio">
            <v-btn icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <template v-slot:input>
              <v-sheet class="pt-4" width="540">
                <html-text-editor :htmlContent="props.item.bio" />
              </v-sheet>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions class="px-4 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="primary" @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { PlayerDetails } from '@/store/client/types';
  import { cloneDeep } from 'lodash-es';
  import UploadImage from '@/components/Ui/UploadImage.vue';
  import { mixins } from '../../mixin/mixins';
  import HtmlTextEditor from './HtmlTextEditor.vue';

  export default Vue.extend({
    name: 'PlayerEditModal',
    mixins: [mixins],
    components: {
      UploadImage,
      HtmlTextEditor,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object as PropType<PlayerDetails>,
        required: true,
      },
    },
    provide(): any {
      return {
        setHtml: this.setHtml,
      };
    },
    data() {
      return {
        headers: [
          { text: 'Image', value: 'image' },
          { text: '#', value: 'jerseyNumber' },
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Pos.', value: 'position' },
          { text: 'Class', value: 'class' },
          { text: 'Ht.', value: 'height' },
          { text: 'Wt.', value: 'weight' },
          { text: 'Hometown', value: 'hometown' },
          { text: 'High School', value: 'highSchool' },
          { text: 'Bio', value: 'bio', cellClass: 'custom-cell' },
        ],
        items: [] as PlayerDetails[],
      };
    },
    methods: {
      handleTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.items, id, order, 'clientRosterPlayerId');
      },
      handleShiftTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.items, id, order, 'clientRosterPlayerId');
      },
      save() {
        // console.log('Dialog Saved');
      },
      cancel() {
        // console.log('Dialog cancel');
      },
      open() {
        // console.log('Dialog open');
      },
      setHtml(html: string) {
        this.items[0].bio = html;
      },
      onSave() {
        this.$emit('submit', this.items[0]);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) this.items = [cloneDeep(this.data)];
      },
    },
  });
</script>
<style lang="scss">
  .cell-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-height: 1.5rem;
  }

  .roster-table {
    .custom-cell {
      max-width: 100px;

      .v-small-dialog__activator {
        @extend .cell-truncate;

        .v-small-dialog__activator__content {
          @extend .cell-truncate;
          display: block;
        }
      }
    }
  }
</style>
