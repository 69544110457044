<template>
  <v-dialog
    content-class="elevation-2"
    width="960"
    :value="value"
    @input="$emit('input', $event)"
    :persistent="submittingForm"
  >
    <v-form :disabled="submittingForm" @submit.prevent="submitForm">
      <v-card rounded="lg" :loading="submittingForm ? 'white' : false">
        <v-card-title class="text-captalize white--text primary py-6">Edit Client</v-card-title>
        <v-card-text class="pt-6 px-6">
          <v-row>
            <v-col cols="4">
              <label class="grey--text text--darken-1">Client Name </label>
              <v-text-field outlined dense hide-details class="mb-5 mt-2" v-model="formData.name"></v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="grey--text text--darken-1">Client Site Url</label>
              <v-text-field outlined dense hide-details class="mb-5 mt-2" v-model="formData.siteUrl"></v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="grey--text text--darken-1">Client Global Id</label>
              <v-text-field
                outlined
                dense
                hide-details
                class="mb-5 mt-2"
                v-model="formData.globalClientId"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label class="grey--text text--darken-1">Client Location</label>
              <v-text-field outlined dense hide-details class="mb-5 mt-2" v-model="formData.location"></v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="grey--text text--darken-1">Client Tenant Id</label>
              <v-text-field outlined dense hide-details class="mb-5 mt-2" v-model="formData.tenantId"></v-text-field>
            </v-col>
            <v-col cols="4">
              <label class="grey--text text--darken-1">State</label>
              <v-autocomplete
                class="mb-5 mt-2"
                hide-details
                :items="states"
                item-text="name"
                item-value="abbreviation"
                v-model="formData.state"
                no-data-text="No Results Found"
                :menu-props="{ closeOnClick: true }"
                outlined
                dense
              >
                <template #prepend-item>
                  <v-subheader class="text-body-1">Select State</v-subheader>
                </template>
                <template #item="{ on, attrs, item }">
                  <v-list-item v-on="on" v-bind="attrs">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template #selection="{ item }">
                  {{ item.name }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label class="grey--text text--darken-1">Client Logo</label>
              <UploadImage
                :value="formData.logoUrl"
                :fileUrl="formData.logoUrl"
                @input="formData.logoUrl = $event"
                :client-id="formData.clientId"
                key="2"
                class="mb-5 mt-2"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <btn type="submit" color="primary" :disabled="submittingForm">Update</btn>
          <btn @click="closeModal" :text="!!selected" :color="selected ? '' : 'primary'" :disabled="submittingForm">
            Cancel
          </btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { Btn } from '@/components';
  import { mapActions, mapGetters } from 'vuex';
  import UploadImage from '@/components/Ui/UploadImage.vue';
  import { states } from './states';
  export default Vue.extend({
    components: { Btn, UploadImage },
    props: {
      value: { type: Boolean, default: false },
      client: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        selected: null,
        submittingForm: false,
        loadingClients: false,
        formData: { ...this.client },
        states: states,
      };
    },
    computed: {
      ...mapGetters('global', { clients: 'getClients' }),
    },
    methods: {
      async submitForm() {
        this.submittingForm = true;
        try {
          const { id, globalClientId, name, logoUrl, siteUrl, location, state, tenantId } = this.formData;

          await this.updateClient({
            id: id,
            globalClientId: globalClientId,
            tenantId: tenantId,
            name,
            logoUrl,
            siteUrl,
            location: `${location}, ${state}`,
          });
          this.handleSuccess();
        } catch (error) {
          this.handleFailure();
        }
        this.submittingForm = false;
      },
      handleSuccess() {
        this.showToast('Client updated successfully');
        this.closeModal();
        this.$emit('save');
      },
      handleFailure() {
        this.showToast('Could not update client');
        this.submittingForm = false;
      },
      resetForm() {
        this.submittingForm = false;
        this.selected = null;
      },
      closeModal() {
        this.$emit('input', false);
      },
      ...mapActions('ui', { showToast: 'showToast' }),
      ...mapActions('global', ['fetchClients']),
      ...mapActions('client', ['updateClient']),
    },
    mounted() {
      const location = this.formData.location.split(',');
      this.formData.state = location.length >= 2 ? location[location.length - 1].trim() : '';
      this.formData.location =
        location.length >= 2
          ? this.formData.location.replace(`,${location[location.length - 1]}`, '')
          : this.formData.location;
      this.$forceUpdate();
    },
  });
</script>
