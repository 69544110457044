<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog :value="value" @input="$emit('input', $event)" fullscreen persistent scrollable>
    <v-card>
      <v-card-title class="px-4 pt-4 gap-2 elevation-5" style="z-index: 10">
        Schedule
        <v-spacer></v-spacer>
        <v-btn
          depressed
          class="text-capitalize"
          @click="onRemove"
          :disabled="itemsData[tab] === 'Results' ? !selectedResults.length : !selectedUpcoming.length"
          >Remove</v-btn
        >
        <v-btn color="primary" depressed class="text-capitalize" @click="onAdd">Add Event</v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-tabs v-model="tab" grow class="px-4 pt-4">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="item in itemsData" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="px-4">
          <v-card flat>
            <div class="pt-0 my-4 d-flex flex-column gap-4">
              <!-- Results TABLE -->
              <v-data-table
                v-if="itemsData[tab] === 'Results'"
                class="schedule-table"
                disable-sort
                disable-filtering
                disable-pagination
                hide-default-footer
                :headers="headersResults"
                :items="itemsResults"
                item-key="resultId"
                v-model="selectedResults"
                show-select
              >
                <template #item.opponentLogo="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.opponentLogo"
                    large
                    persistent
                    @save="save"
                    @cancel="cancel"
                  >
                    <v-img
                      height="48"
                      width="48"
                      :alt="props.item?.opponentLogoAltText || 'Opponent Logo'"
                      :src="props.item?.opponentLogo || '/default-img.png'"
                      class="rounded-sm"
                    />
                    <template v-slot:input>
                      <div class="py-4">
                        <!-- <label class="grey--text text--darken-1">Page Sponsor Image</label> -->
                        <upload-image
                          :value="props.item?.opponentLogo"
                          height="200"
                          width="200"
                          :fileUrl="props.item.opponentLogo"
                          @input="itemsResults[props.index].opponentLogo = $event"
                          key="2"
                          class="mb-5 mt-2"
                        />
                        <!-- :client-id="program.clientId" -->
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.opponent="props">
                  <v-edit-dialog :return-value.sync="props.item.opponent">
                    <span v-if="props.item.opponent" :id="`${props.item.resultId}-0`">{{ props.item.opponent }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.resultId}-0`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.opponent"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="handleTabPressResults($event.target.id, props.item.resultId, 0)"
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResults($event.target.id, props.item.resultId, 0)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.location="props">
                  <v-edit-dialog :return-value.sync="props.item.location">
                    <span v-if="props.item.location" :id="`${props.item.resultId}-1`">{{ props.item.location }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.resultId}-1`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.location"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="handleTabPressResults($event.target.id, props.item.resultId, 1)"
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResults($event.target.id, props.item.resultId, 1)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.stadiumName="props">
                  <v-edit-dialog :return-value.sync="props.item.stadiumName">
                    <span v-if="props.item.stadiumName" :id="`${props.item.resultId}-2`">{{
                      props.item.stadiumName
                    }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.resultId}-2`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.stadiumName"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="handleTabPressResults($event.target.id, props.item.resultId, 2)"
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResults($event.target.id, props.item.resultId, 2)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.locationIndicator="props">
                  <v-edit-dialog :return-value.sync="props.item.locationIndicator">
                    <span v-if="props.item.locationIndicator" :id="`${props.item.resultId}-3`">{{
                      LocationIndicatorParse(props.item.locationIndicator)
                    }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.resultId}-3`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.locationIndicator"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="handleTabPressResults($event.target.id, props.item.resultId, 3)"
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResults($event.target.id, props.item.resultId, 3)
                          "
                          single-line
                          persistent-hint
                          hint="vs/at"
                        ></v-text-field>
                        <div class="v-text-field__details" style="margin-top: 5px">
                          <div class="v-messages theme--light">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">vs/at</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.date="props">
                  <!-- <v-edit-dialog :return-value.sync="props.item.date">
                    <span v-if="props.item.date" v-format-date:[dateFormat]="props.item.date">{{ props.item.date }}</span>
                    <span class="text--disabled">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field outlined dense hide-details v-model="props.item.date" single-line></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog> -->
                  <v-menu
                    ref="menuDate"
                    v-model="props.item.menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <label class="grey--text text--darken-1">Date</label> -->
                      <v-text-field
                        v-model="props.item.date"
                        dense
                        flat
                        solo
                        hide-details
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        class="mb-0 mt-2 date-picker"
                        v-on="on"
                      ></v-text-field>
                      <!-- persistent-hint
                      hint="YYYY-MM-DD format" -->
                    </template>
                    <v-date-picker
                      landscape
                      v-model="props.item.date"
                      @input="props.item.menuDate = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <template #item.time="props">
                  <v-edit-dialog :return-value.sync="props.item.time">
                    <span v-if="props.item.time" :id="`${props.item.resultId}-4`">{{ props.item.time }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.resultId}-4`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.time"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="handleTabPressResults($event.target.id, props.item.resultId, 4)"
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResults($event.target.id, props.item.resultId, 4)
                          "
                          single-line
                        >
                        </v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.result="props">
                  <v-edit-dialog :return-value.sync="props.item.result">
                    <span v-if="props.item.result" :id="`${props.item.resultId}-5`">{{ props.item.result }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.resultId}-5`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.result"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="handleTabPressResults($event.target.id, props.item.resultId, 5)"
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResults($event.target.id, props.item.resultId, 5)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>

              <!-- Upcoming Evetns TABLE -->
              <v-data-table
                v-if="itemsData[tab] === 'Upcoming Events'"
                class="schedule-table"
                height="50vh"
                disable-sort
                disable-filtering
                disable-pagination
                hide-default-footer
                :headers="headersUpcoming"
                :items="itemsUpcoming"
                item-key="upcomingId"
                v-model="selectedUpcoming"
                show-select
              >
                <template #item.opponentLogo="props">
                  <v-edit-dialog
                    :return-value.sync="props.item.opponentLogo"
                    large
                    persistent
                    @save="save"
                    @cancel="cancel"
                    @open="open"
                  >
                    <v-img
                      height="48"
                      width="48"
                      :alt="props.item?.opponentAltText || 'Opponent Logo'"
                      :src="props.item?.opponentLogo || '/default-img.png'"
                      class="rounded-sm"
                    />
                    <template v-slot:input>
                      <div class="py-4">
                        <upload-image
                          :value="props.item?.opponentLogo"
                          height="200"
                          width="200"
                          :fileUrl="props.item.opponentLogo"
                          @input="itemsUpcoming[props.index].opponentLogo = $event"
                          key="2"
                          class="mb-5 mt-2"
                        />
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.opponent="props">
                  <v-edit-dialog :return-value.sync="props.item.opponent">
                    <span v-if="props.item.opponent" :id="`${props.item.upcomingId}-0`">{{ props.item.opponent }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.upcomingId}-0`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.opponent"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="
                            handleTabPressUpcoming($event.target.id, props.item.upcomingId, 0)
                          "
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResultsUpcoming($event.target.id, props.item.upcomingId, 0)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.location="props">
                  <v-edit-dialog :return-value.sync="props.item.location">
                    <span v-if="props.item.location" :id="`${props.item.upcomingId}-1`">{{ props.item.location }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.upcomingId}-1`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.location"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="
                            handleTabPressUpcoming($event.target.id, props.item.upcomingId, 1)
                          "
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResultsUpcoming($event.target.id, props.item.upcomingId, 1)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.stadiumName="props">
                  <v-edit-dialog :return-value.sync="props.item.stadiumName">
                    <span v-if="props.item.stadiumName" :id="`${props.item.upcomingId}-2`">{{
                      props.item.stadiumName
                    }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.upcomingId}-2`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.stadiumName"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="
                            handleTabPressUpcoming($event.target.id, props.item.upcomingId, 2)
                          "
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResultsUpcoming($event.target.id, props.item.upcomingId, 2)
                          "
                          single-line
                        ></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.locationIndicator="props">
                  <v-edit-dialog :return-value.sync="props.item.locationIndicator">
                    <span v-if="props.item.locationIndicator" :id="`${props.item.upcomingId}-3`">{{
                      LocationIndicatorParse(props.item.locationIndicator)
                    }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.upcomingId}-3`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.locationIndicator"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="
                            handleTabPressUpcoming($event.target.id, props.item.upcomingId, 3)
                          "
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResultsUpcoming($event.target.id, props.item.upcomingId, 3)
                          "
                          single-line
                        ></v-text-field>
                        <div class="v-text-field__details" style="margin-top: 5px">
                          <div class="v-messages theme--light">
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">vs/at</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
                <template #item.date="props">
                  <!-- <v-edit-dialog :return-value.sync="props.item.date">
                    <span v-if="props.item.date" v-format-date:[dateFormat]="props.item.date">{{ props.item.date }}</span>
                    <span v-else class="text--disabled">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field outlined dense hide-details v-model="props.item.date" single-line></v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog> -->
                  <v-menu
                    ref="menuDate"
                    v-model="props.item.menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <label class="grey--text text--darken-1">Date</label> -->
                      <v-text-field
                        v-model="props.item.date"
                        dense
                        flat
                        solo
                        hide-details
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        class="mb-0 mt-2"
                        v-on="on"
                      ></v-text-field>
                      <!-- persistent-hint
                      hint="YYYY-MM-DD format" -->
                    </template>
                    <v-date-picker
                      landscape
                      v-model="props.item.date"
                      @input="props.item.menuDate = false"
                    ></v-date-picker>
                  </v-menu>
                </template>
                <template #item.time="props">
                  <v-edit-dialog :return-value.sync="props.item.time">
                    <span v-if="props.item.time" :id="`${props.item.upcomingId}-4`">{{ props.item.time }}</span>
                    <span v-else class="text--disabled" :id="`${props.item.upcomingId}-4`">&mdash;</span>
                    <template v-slot:input>
                      <div class="py-4">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="props.item.time"
                          @blur="triggerEnterKeyPressEvent($event.target.id)"
                          @keydown.tab.exact.prevent="
                            handleTabPressUpcoming($event.target.id, props.item.upcomingId, 4)
                          "
                          @keydown.shift.tab.prevent="
                            handleShiftTabPressResultsUpcoming($event.target.id, props.item.upcomingId, 4)
                          "
                          single-line
                        >
                        </v-text-field>
                      </div>
                    </template>
                  </v-edit-dialog>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="pa-4 elevation-5" style="z-index: 10">
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="primary" @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { cloneDeep, sortBy, uniqueId } from 'lodash-es';
  import { mixins } from '../../mixin/mixins';
  import UploadImage from '@/components/Ui/UploadImage.vue';
  import { ScheduleRaw, ScheduleEvents } from '@/store/client/types';
  import { DateTime } from 'luxon';
  export default Vue.extend({
    name: 'ScheduleBulkEditModal',
    mixins: [mixins],
    components: { UploadImage },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object as PropType<ScheduleRaw>,
        required: true,
      },
    },
    data() {
      return {
        dateFormat: 'M/d/yy',
        headersResults: [
          { text: 'Opponent Logo', value: 'opponentLogo' },
          { text: 'Opponent Name', value: 'opponent' },
          { text: 'Location', value: 'location' },
          { text: 'Stadium Name', value: 'stadiumName' },
          { text: 'Indicator', value: 'locationIndicator' },
          { text: 'Date', value: 'date' },
          { text: 'Time', value: 'time' },
          { text: 'Result', value: 'result' },
        ],
        headersUpcoming: [
          { text: 'Opponent Logo', value: 'opponentLogo' },
          { text: 'Opponent Name', value: 'opponent' },
          { text: 'Location', value: 'location' },
          { text: 'Stadium Name', value: 'stadiumName' },
          { text: 'Indicator', value: 'locationIndicator' },
          { text: 'Date', value: 'date' },
          { text: 'Time', value: 'time' },
        ],
        tab: null,
        itemsData: ['Upcoming Events', 'Results'],
        itemsResults: [] as ScheduleEvents[],
        itemsUpcoming: [] as ScheduleEvents[],
        selectedResults: [] as ScheduleEvents[],
        selectedUpcoming: [] as ScheduleEvents[],
      };
    },
    methods: {
      handleTabPressUpcoming(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.itemsUpcoming, id, order, 'upcomingId');
      },
      handleShiftTabPressResultsUpcoming(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.itemsUpcoming, id, order, 'upcomingId');
      },
      handleTabPressResults(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.itemsResults, id, order, 'resultId');
      },
      handleShiftTabPressResults(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.itemsResults, id, order, 'resultId');
      },
      save() {
        // console.log('Dialog Saved');
      },
      cancel() {
        // console.log('Dialog cancel');
      },
      open() {
        // console.log('Dialog open');
      },
      LocationIndicatorParse(indicator: string): string {
        return indicator.toLocaleLowerCase() === 'h' ? 'Vs' : indicator.toLocaleLowerCase() === 'vs' ? 'Vs' : 'At';
      },
      genId(): number {
        let id = parseInt(uniqueId());
        if (this.itemsData[this.tab] === 'Results') {
          while (this.itemsResults.some((item) => item.resultId === id)) {
            id = parseInt(uniqueId());
          }
          return id;
        } else {
          while (this.itemsUpcoming.some((item) => item.upcomingId === id)) {
            id = parseInt(uniqueId());
          }
          return id;
        }
      },
      onAdd() {
        if (this.itemsData[this.tab] === 'Results') {
          this.itemsResults.push({
            resultId: this.genId(),
            opponent: null,
            location: null,
            stadiumName: null,
            locationIndicator: null,
            opponentLogo: null,
            date: null,
            time: null,
            result: null,
          });
        } else {
          this.itemsUpcoming.push({
            upcomingId: this.genId(),
            opponent: null,
            location: null,
            stadiumName: null,
            locationIndicator: null,
            opponentLogo: null,
            date: null,
            time: null,
          });
        }
      },
      onRemove() {
        if (this.itemsData[this.tab] === 'Results') {
          const idsToRemove = this.selectedResults.map((item) => {
            return item.resultId;
          });
          this.itemsResults = this.itemsResults.filter((item) => {
            return !idsToRemove.includes(item.resultId);
          });
          this.selectedResults = [];
        } else {
          const idsToRemove = this.selectedUpcoming.map((item) => item.upcomingId);
          this.itemsUpcoming = this.itemsUpcoming.filter((item) => !idsToRemove.includes(item.upcomingId));
          this.selectedUpcoming = [];
        }
      },
      onSave() {
        this.$emit('submit', { results: this.itemsResults, upcoming: this.itemsUpcoming });
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) {
          this.itemsResults = sortBy(cloneDeep(this.data.results), ['date']).map((ele) => {
            return {
              ...ele,
              date: DateTime.fromISO(ele.date).toFormat('yyyy-MM-dd'),
              menuDate: false,
              resultId: this.genId(),
            };
          });
          this.itemsUpcoming = sortBy(cloneDeep(this.data.upcoming), ['date']).map((ele) => {
            return {
              ...ele,
              date: DateTime.fromISO(ele.date).toFormat('yyyy-MM-dd'),
              menuDate: false,
              upcomingId: this.genId(),
            };
          });
        }
      },
    },
  });
</script>
<style lang="scss">
  .cell-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-height: 1.5rem;
  }

  .schedule-table {
    .custom-cell {
      max-width: 100px;

      .v-small-dialog__activator {
        @extend .cell-truncate;

        .v-small-dialog__activator__content {
          @extend .cell-truncate;
          display: block;
        }
      }
    }
  }
</style>
