<template>
  <v-dialog persistent width="640" :value="value" @input="$emit('input', $event)">
    <v-form ref="form" @submit.prevent="onSubmit" v-model="isValid" lazy-validation>
      <v-card>
        <v-card-title class="pt-6 pb-4 px-6">{{ title }} Edit</v-card-title>
        <v-card-text clas s="px-6 py-0">
          <label class="grey--text text--darken-1">{{ config.imageLabel }}</label>

          <upload-image
            :value="formData.image"
            :fileUrl="formData.image"
            :clientId="program.clientId"
            @input="formData.image = $event"
            class="mb-5 mt-2"
          />
          <label class="grey--text text--darken-1">Image Alt Text</label>
          <v-text-field
            class="mt-2"
            autofocus
            autocomplete="off"
            dense
            name="text-field"
            outlined
            v-model="formData.alt"
            :rules="[required]"
          ></v-text-field>

          <label class="grey--text text--darken-1">{{ config.placeholderFirstText }}</label>
          <v-text-field
            class="mt-2"
            autocomplete="off"
            dense
            name="text-field"
            outlined
            v-model="formData.firstText"
          ></v-text-field>

          <label class="grey--text text--darken-1">{{ config.placeholderSecondText }}</label>
          <v-text-field
            class="mt-2"
            autocomplete="off"
            dense
            name="text-field"
            outlined
            v-model="formData.secondText"
          ></v-text-field>
          <template v-if="config.placeholderThirdText">
            <label class="grey--text text--darken-1">{{ config.placeholderThirdText }}</label>
            <v-text-field
              class="mt-2"
              autocomplete="off"
              dense
              name="text-field"
              outlined
              v-model="formData.thirdText"
            ></v-text-field>
          </template>
          <template v-if="config.placeholderFourthText">
            <label class="grey--text text--darken-1">{{ config.placeholderFourthText }}</label>
            <v-text-field
              class="mt-2"
              autocomplete="off"
              dense
              name="text-field"
              outlined
              v-model="formData.fourthText"
            ></v-text-field>
          </template>
        </v-card-text>
        <v-card-actions class="px-6 pb-6 pt-0 d-flex justify-space-between">
          <v-btn type="submit" class="text-capitalize" color="primary" depressed :disabled="!isValid">Continue</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize" color="grey lighten-2" @click="reset" depressed>Clear</v-btn>
          <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script lang="ts">
  import { VForm } from '@/types';
  import UploadImage from '@/components/Ui/UploadImage.vue';

  import Vue from 'vue';
  export default Vue.extend({
    name: 'TextImageModal',
    components: {
      UploadImage,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      config: {
        type: Object,
        default: null,
      },
      data: {
        type: Object,
        required: true,
      },
      program: {
        type: Object,
      },
    },
    data() {
      return {
        formData: {
          image: this.data.image,
          alt: this.data.alt,
          firstText: this.data.firstText,
          secondText: this.data.secondText,
          thirdText: this.data.thirdText,
          fourthText: this.data.fourthText,
        },
        isValid: false,
      };
    },
    methods: {
      required(value: string) {
        return (typeof value === 'string' && !!value.trim()) || 'Field is required';
      },
      onSubmit() {
        const form = this.$refs.form as VForm;
        if (!form.validate()) return;
        this.$emit('submit', this.formData);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      reset() {
        this.formData.image = '';
        const form = this.$refs.form as VForm;
        form.reset();
      },
    },
    mounted() {
      (this.$refs['form'] as any).resetValidation();
    },
  });
</script>
