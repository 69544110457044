<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="917" persistent scrollable>
    <v-card rounded="lg" :loading="loading ? 'white' : false">
      <v-card-title class="text-captalize white--text primary py-4 px-6 text-h6">Create New Page</v-card-title>
      <v-stepper v-model="currentStep" class="custom-stepper">
        <v-stepper-header class="overflow-x-auto elevation-5">
          <template v-for="(step, n) in steps">
            <v-stepper-step :key="`${n}-step`" :complete="currentStep > n + 1" :step="n + 1">
              {{ step.name }}
            </v-stepper-step>
            <v-divider v-if="n + 1 < steps.length" :key="n"></v-divider>
          </template>
        </v-stepper-header>
        <v-stepper-content v-for="(step, n) in steps" :step="n + 1" :key="n" class="px-0 py-0">
          <component v-if="step.component" :is="step.component" :programId="program" :ref="step.ref" />
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import UploadImage from '@/components/Ui/UploadImage.vue';
  import { mapActions } from 'vuex';
  import StoryImporter from './StoryImporter.vue';
  import RosterImporter from './RosterImporter.vue';
  import OpponentRosterImporter from './OpponentRosterImporter.vue';
  import ScheduleImporter from './ScheduleImporter.vue';
  import OpponentScheduleImporter from './OpponentScheduleImporter.vue';
  import FeaturedCoachImporter from './FeaturedCoachImporter.vue';
  import CoachImporter from './CoachImporter.vue';
  import PageInfo from './PageInfo.vue';
  import PageDetails from './PageDetails.vue';
  import FeaturedPlayerImporter from './FeaturedPlayerImporter.vue';
  import OpponentCoachImporter from './OpponentCoachImporter.vue';

  interface Step {
    id: number;
    name: string;
    component: VueConstructor;
    ref: string;
  }
  const importMap: Record<string, VueConstructor> = {
    story: StoryImporter,
    roster: RosterImporter,
    opponentRoster: OpponentRosterImporter,
    schedule: ScheduleImporter,
    opponentSchedule: OpponentScheduleImporter,
    featuredPlayer: FeaturedPlayerImporter,
    featuredCoach: FeaturedCoachImporter,
    coach: CoachImporter,
    opponentCoach: OpponentCoachImporter,
  };
  export default Vue.extend({
    name: 'NewPageModal',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      program: {
        type: [String, Number],
        required: true,
      },
    },
    components: {
      UploadImage,
    },
    provide(): any {
      return {
        toggleLoading: this.toggleLoading,
        setType: this.setType,
        setImportType: this.setImportType,
        setTemplateId: this.setTemplateId,
        getTemplateId: this.getTemplateId,
        getPageTitle: this.getPageTitle,
        setPageTitle: this.setPageTitle,
        setPageContent: this.setPageContent,
        getPageContent: this.getPageContent,
        getPageSport: this.getPageSport,
        getPageEvent: this.getPageEvent,
        setPageSport: this.setPageSport,
        setPageEvent: this.setPageEvent,
        goNext: this.goNext,
        goBack: this.goBack,
        close: this.close,
      };
    },
    data() {
      return {
        loading: false,
        currentStep: 1,
        template: null as number,
        pageSport: null,
        pageEvent: null,
        pageTitle: null as string,
        content: null as any,
        steps: [
          {
            id: 1,
            name: 'Page Info',
            component: PageInfo,
            ref: 'pageInfo',
          },
          {
            id: 3,
            name: 'Page Details',
            component: PageDetails,
            ref: 'pageDetails',
          },
        ] as Step[],
      };
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      setTemplateId(id: number | null) {
        this.template = id;
      },
      getTemplateId(): number | null {
        return this.template;
      },
      setPageTitle(title: string) {
        this.pageTitle = title;
      },
      getPageTitle(): string {
        return this.pageTitle;
      },
      setPageContent(content: any) {
        this.content = content;
      },
      getPageContent(): any {
        return this.content;
      },
      close() {
        this.$emit('input', false);
      },
      toggleLoading() {
        this.loading = !this.loading;
      },
      setType(type: string) {
        const { steps } = this;
        const importStep: Step = {
          id: 2,
          name: 'Page Import',
          component: null,
          ref: null,
        };
        const importStepExists = steps.findIndex((step) => step.id === 2) >= 0;
        if (type === 'import' && !importStepExists) steps.splice(1, 0, importStep);
        if (type === 'template' && importStepExists) steps.splice(1, 1);
      },
      setImportType(importType: string) {
        if (this.steps.length > 2)
          this.steps[1].component = typeof importType === 'string' ? importMap[importType] : null;
      },
      setPageSport(sport: any) {
        this.pageSport = sport;
      },
      getPageSport(): any {
        return this.pageSport;
      },
      setPageEvent(event: any) {
        this.pageEvent = event;
      },
      getPageEvent(): any {
        return this.pageEvent;
      },
      goNext() {
        this.currentStep++;
        if (this.currentStep == 3 && this.pageTitle) {
          (this.$refs.pageDetails as any)[0].setTitle(this.pageTitle);
        }
      },
      goBack() {
        this.currentStep--;
      },
    },
  });
</script>
<style lang="scss">
  .custom-stepper .v-stepper__wrapper {
    overflow: visible;
  }
  .scrollable {
    max-height: calc(100vh - 400px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .v-card__actions > .v-btn.v-btn {
    padding: 0 16px;
  }
</style>
