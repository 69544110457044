import API from '@/services/api/api';
import { AxiosResponse } from 'axios';

interface fileResultType {
  status: number;
  file: string;
}

interface urlResultType {
  status: number;
  url: string;
}

export const fileDelete = async (fileName: string, clientId: number) => {
  const url = `/aws/s3?fileName=${fileName}&clientId=${clientId}`;

  const result: fileResultType = {
    status: null,
    file: '',
  };

  return API.delete(url)
    .then((res: AxiosResponse) => {
      result.status = res?.status || 200;
      return result;
    })
    .catch((e: any) => {
      result.status = e.response?.status || 400;
      return result;
    });
};

export const fileUpload = async (file: File, folder: string, clientId: number) => {
  const url = `/aws/s3?clientId=${clientId}`;

  const result: fileResultType = {
    status: null,
    file: '',
  };

  try {
    const res = await API.post(
      url,
      { clientId, file },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    result.status = res.request?.status;
    result.file = JSON.parse(res.request.response).url;
    return result;
  } catch (e) {
    result.status = e.response?.status || 400;
    return result;
  }
};

export const publicationUpload = async (blob: Blob, clientId: number) => {
  const url = `aws/s3/publications?clientId=${clientId}`;
  const result: urlResultType = {
    status: null,
    url: '',
  };

  try {
    const res = await API.post(
      url,
      { file: blob, clientId },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    result.status = res.request?.status;
    result.url = JSON.parse(res.request.response).url;
    return result;
  } catch (e) {
    result.status = e.response.status;
    return result;
  }
};
