<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog :value="value" @input="$emit('input', $event)" width="960" persistent>
    <v-card>
      <v-card-title class="px-4 pt-4 gap-2">
        Rankings
        <v-spacer></v-spacer>
        <v-btn depressed class="text-capitalize" @click="onRemove">Remove</v-btn>
        <v-btn color="primary" depressed class="text-capitalize" @click="onAdd">Add Team</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          class="standing-table"
          disable-sort
          disable-filtering
          disable-pagination
          hide-default-footer
          :headers="headers"
          :items="rankingItems"
          item-key="id"
          v-model="selected"
          show-select
          @toggle-select-all="selectAllItems"
        >
          <template v-slot:body="{ items }">
            <draggable v-if="items.length" v-model="rankingItems" item-key="name" tag="tbody" ghost-class="ghost">
              <template>
                <tr v-for="(item, index) in items" :key="item.id">
                  <td>
                    <v-simple-checkbox v-model="items[index].isSelected"></v-simple-checkbox>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.rank">
                      <span v-if="item.rank" :id="`${item.id}-0`">{{ item.rank }}</span>
                      <span v-else class="text--disabled" :id="`${item.id}-0`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.rank"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 0)"
                            @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 0)"
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.image" large persistent @save="save" @cancel="cancel">
                      <v-img
                        height="48"
                        width="48"
                        :alt="item?.imageAltText || 'Player Headshot'"
                        :src="item?.image || '/default-img.png'"
                        class="rounded-sm"
                      />
                      <template v-slot:input>
                        <div class="py-4">
                          <upload-image
                            :value="item?.image"
                            height="200"
                            width="200"
                            :fileUrl="item.image"
                            @input="rankingItems[index].image = $event"
                            key="2"
                            class="mb-5 mt-2"
                          />
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.team">
                      <span v-if="item.team" :id="`${item.id}-1`">{{ item.team }}</span>
                      <span v-else class="text--disabled" :id="`${item.id}-1`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.team"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 1)"
                            @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 1)"
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.record">
                      <span v-if="item.record" :id="`${item.id}-2`">{{ item.record }}</span>
                      <span v-else class="text--disabled" :id="`${item.id}-2`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.record"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 2)"
                            @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 2)"
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <td>
                    <v-edit-dialog :return-value.sync="item.prev">
                      <span v-if="item.prev" :id="`${item.id}-3`">{{ item.prev }}</span>
                      <span v-else class="text--disabled" :id="`${item.id}-3`">&mdash;</span>
                      <template v-slot:input>
                        <div class="py-4">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="item.prev"
                            @blur="triggerEnterKeyPressEvent($event.target.id)"
                            @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 3)"
                            @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 3)"
                            single-line
                          ></v-text-field>
                        </div>
                      </template>
                    </v-edit-dialog>
                  </td>
                </tr>
              </template>
            </draggable>
            <tbody v-else>
              <tr class="v-data-table__empty-wrapper">
                <td :colspan="++headers.length">No data available</td>
              </tr>
            </tbody>
          </template>
          <template v-slot:item.data-table-select="{ isSelected, select }">
            <v-simple-checkbox color="green" :value="isSelected" @input="select($event)"></v-simple-checkbox>
          </template>
        </v-data-table>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions class="px-4 pb-4">
        <v-btn class="text-capitalize" depressed color="primary" @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { cloneDeep, uniqueId } from 'lodash-es';
  import { Division, TeamPerDivision } from '@/store/client/types';
  import UploadImage from '@/components/Ui/UploadImage.vue';
  import { mixins } from '../../mixin/mixins';
  import draggable from 'vuedraggable';
  export default Vue.extend({
    name: 'RankingEditModal',
    mixins: [mixins],
    components: { draggable, UploadImage },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Array as PropType<Division[]>,
        required: true,
      },
    },
    data() {
      return {
        headers: [
          {
            text: 'Rank',
            align: 'start',
            value: 'rank',
          },
          {
            text: 'Image',
            align: 'start',
            value: 'image',
          },
          {
            text: 'Team',
            align: 'start',
            value: 'team',
          },

          {
            text: 'Record',
            align: 'start',
            value: 'record',
          },
          {
            text: 'Prev',
            align: 'start',
            value: 'prev',
          },
        ],
        rankingItems: [],
        selected: [] as TeamPerDivision[],
        numberOfDivision: 0,
        numberOfTeamsPerDivision: 0,
      };
    },
    methods: {
      handleTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.items, id, order);
      },
      handleShiftTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.items, id, order);
      },
      save() {
        // console.log('Dialog Saved');
      },
      cancel() {
        // console.log('Dialog cancel');
      },
      open() {
        // console.log('Dialog open');
      },
      onRemove() {
        const idsToRemove = this.selectedRankingItems.map((item: any) => item?.id);
        this.rankingItems = this.rankingItems.filter((el) => !idsToRemove.includes(el.id));
        this.selected = [];
      },
      onAdd() {
        this.rankingItems.push({
          id: uniqueId(),
          rank: null,
          team: null,
          record: null,
          prev: null,
          image: '',
        });
      },
      onSave() {
        this.$emit('submit', this.rankingItems);
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      async selectAllItems({ value }: { items: TeamPerDivision[]; value: boolean }) {
        await this.$nextTick();
        this.rankingItems = this.rankingItems.map((item) => {
          return {
            ...item,
            isSelected: value,
          };
        });
      },
    },
    computed: {
      selectedRankingItems(): TeamPerDivision[] {
        return this.rankingItems.filter((item) => item.isSelected);
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) {
          this.rankingItems = cloneDeep(this.data).map((el) => {
            return {
              ...el,
              isSelected: false,
              image: el.image || '',
              id: uniqueId(),
            };
          });
        }
      },
    },
  });
</script>
<style lang="scss">
  .cell-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-height: 1.5rem;
  }

  .standing-table {
    th {
      background: #f5f5f5 !important;
    }
    td:nth-child(2),
    th:nth-child(2),
    td:nth-child(4),
    th:nth-child(4),
    td:nth-child(5),
    th:nth-child(5) {
      width: 100px;
    }
  }
</style>
